import React from "react"
import { StoryGroup } from "components"

const LinkMap = {
  Webpack: "36580017aa04",
  Parcel: "36580017aa04",
  Rollup: "36580017aa04",
  Bundler: "36580017aa04",
  Babel: "36580017aa04",

  "NPM Nedir": "8f41305ea9a4",
  "NPM Link": "45db75de3310",
  LocalPath: "6a064a5717aa",
  "NPM Publish": "b39a5d2e9af2",
}

const stories = [
  {
    title: "NPM Nedir ?",
    postId: "8f41305ea9a4",
  },
  {
    title: "Babel, Webpack, Rollup Nedir ?",
    postId: "36580017aa04",
  },
  {
    title: "React Bileşenleri Oluşturmak — (NPM Publish)",
    postId: "b39a5d2e9af2",
  },

  {
    title: "React Bileşenleri Oluşturmak — (NPM Link)",
    postId: "45db75de3310",
  },
  {
    title: "React Bileşenleri Oluşturmak — (LocalPath)",
    postId: "6a064a5717aa",
  },
  {
    title: "CRA to UMD/NPM Dönüşümü",
    postId: "56b0a60caa45",
  },
  {
    title: "CRA to UMD/NPM Dönüşümü-2",
    postId: "c9d3bd86b367",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Modules",
  path: "react-npm-package",
}

const ReactReusablePage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactReusablePage
